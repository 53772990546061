exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-appdevelopment-js": () => import("./../../../src/pages/appdevelopment.js" /* webpackChunkName: "component---src-pages-appdevelopment-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-digitalmarketing-js": () => import("./../../../src/pages/digitalmarketing.js" /* webpackChunkName: "component---src-pages-digitalmarketing-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-management-js": () => import("./../../../src/pages/itManagement.js" /* webpackChunkName: "component---src-pages-it-management-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-socialmediamarketing-js": () => import("./../../../src/pages/socialmediamarketing.js" /* webpackChunkName: "component---src-pages-socialmediamarketing-js" */),
  "component---src-pages-termsconditions-js": () => import("./../../../src/pages/termsconditions.js" /* webpackChunkName: "component---src-pages-termsconditions-js" */),
  "component---src-pages-uiuxdesign-js": () => import("./../../../src/pages/uiuxdesign.js" /* webpackChunkName: "component---src-pages-uiuxdesign-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-webdevelopment-js": () => import("./../../../src/pages/webdevelopment.js" /* webpackChunkName: "component---src-pages-webdevelopment-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

